import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      //paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    position: 'relative',
    backgroundImage: `url('/static/marmurabg1.jpg')`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 0",
    backgroundSize: "cover",
    '&:before': {
      content: "''",
      display: "block",
      position: "absolute",
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      opacity: 0.7,
      backgroundColor: "white"
    }
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    // marginTop: theme.spacing(2),
    marginTop:0,
    zIndex: 55
  }
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  // nu este integrata chestia asta cu mobile inca.
  // poate ascund acel sidebar cu totul
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={classes.root}>
      <TopBar handleDrawerOpen={() => setIsOpen(true)} isOpen={isOpen} />
      <NavBar handleDrawerClose={() => setIsOpen(false)} isOpen={isOpen} />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout;
