import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from './components/LoadingScreen';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    path: '/',
    layout: DashboardLayout,
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/projects" />
      },
      {
        exact: true,
        path: '/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: "/projects",
        component: lazy(() => import("src/views/projects/ListView"))
      },
      {
        path: "/project/:identifier",
        component: lazy(() => import("src/views/projects/DetailsView"))
      },
      {
        exact: true,
        path: "/concept/project/:identifier/phase/:phase/",
        component: lazy(() => import("src/views/projects/DetailsView/ProjectVersion"))
      },
      {
        exact: true,
        path: "/forecast/project/:identifier/budget/version/:version",
        component: lazy(() => import("src/views/projects/DetailsView/ProjectDevelopment/ForecastBudget"))
      },
      {
        exact: true,
        path: "/forecast/project/:identifier/revenue/version/:version",
        component: lazy(() => import("src/views/projects/DetailsView/ProjectDevelopment/ForecastRevenue"))
      },
      {
        exact: true,
        path: "/finance",
        component: lazy(() => import("src/views/finance"))
      },
      {
        exact: true,
        path: "/finance/invoice/:identifier",
        component: lazy(() => import("src/views/finance/Invoices/DetailsView"))
      },
      {
        exact: true,
        path: "/task-manager",
        component: lazy(() => import("src/views/kanban"))
      },
      {
        exact: true,
        path: "/user-management",
        component: lazy(() => import("src/views/users"))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
