import React from 'react';

const Logo = (props) => {
  return (
    <img style={{
      width: '100%', height: '10rem', transform: 'scale(2.5)'
    }}
      alt="Logo"
      src="/static/generalLogo.svg"
      {...props}
    />
  );
}

export default Logo;
