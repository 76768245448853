export const APP_VERSION = '1.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const USER_ROLES = { ADMIN: "ADMIN", INVESTOR: "INVESTOR" };

export const PROJECT_PHASES = [
  { name: "SOURCING", color: "#43a047" },
  { name: "FEASIBILITY", color: "#43a047" },
  { name: "TRANSACTION", color: "#43a047" },
  { name: "DESIGN", color: "#ffeb3b" },
  { name: "DEVELOPMENT", color: "#f44336" }
];

export const BUDGET_CATEGORIES = {
  LAND: { hr: "Land" },
  CONSTRUCTION_HARD: { hr: "Construction - Hard Cost" },
  CONSTRUCTION_SOFT: { hr: "Construction - Soft Cost" },
  PROFESSIONAL_SERVICES: { hr: "Professional Services" },
  FINANCING: { hr: "Financing" }
}

export const UNIT_TYPES = ["APARTMENT", "PARKING", "OFFICE", "STORAGE", "RETAIL", "OTHER"];

export const CSV_REVENUE_HEADER = [
  { label: "Building", key: "building" },
  { label: "Floor Name", key: "floorName" },
  { label: "Unit Type", key: "unitType" },
  { label: "Unit No", key: "unitNo" },
  { label: "Room No", key: "roomNo" },
  { label: "SQM Actual built", key: "actualBuilt" },
  { label: "SQM Built Sellable", key: "sqmBuilt" },
  { label: "SQM Util", key: "sqmUtil" },
  { label: "Balconies", key: "balconies" },
  { label: "Common Area/Loss", key: "commonArea" },
  { label: "€ / SQM Built Apartment", key: "valuePerSqmBuilt" },
  { label: "€ / SQM Util Blended", key: "valuePerSqmUtil" },
  { label: "€ / SQM Util Balconies", key: "valuePerSqmBalconies" },
  { label: "Floor Total Sales", key: "totalRevenue" },
];

export const CSV_REVENUE_HEADER_DEVELOPMENT = [
  ...CSV_REVENUE_HEADER,
  { label: "Actual sale €", key: "actualSale" },
  { label: "Forecasted €", key: "forecasted" }
]

export const CSV_BUDGET_HEADER = [
  { label: "Category", key: "category" },
  { label: "Item", key: "item" },
  { label: "Casahflow Category", key: "cashflowCategory" },
  { label: "Parameter", key: "parameter" },
  { label: "Parameter Value", key: "parameterValue" },
  { label: "% or Value cost", key: "costValue" },
  { label: "Value", key: "value" },
  { label: "Total Percent", key: "totalPercent" }
];

export const CSV_BUDGET_HEADER_DEVELOPMENT = [
  ...CSV_BUDGET_HEADER,
  { label: "Forecasted", key: "forecasted" },
  { label: "Actual", key: "actualBudget" },
];
