import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListSubheader, CssBaseline, IconButton, Divider, Drawer } from '@material-ui/core';
import NavItem from './NavItem';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {
  DashboardOutlined as HomeIcon,
  ReceiptOutlined as ReceiptIcon,
  FolderSpecialOutlined as ProjectIcon,
  AccountBox as UserIcons,
  VerticalSplit as VerticalSplitIcon
} from '@material-ui/icons';

import { APP_VERSION, USER_ROLES } from 'src/constants';
import useAuth from 'src/hooks/useAuth';

const sections = [
  {
    subheader: '',
    items: [
      {
        title: 'Dashboard',
        icon: HomeIcon,
        href: '/',
        allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.INVESTOR]
      },
      {
        title: 'Projects',
        icon: ProjectIcon,
        href: '/projects',
        allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.INVESTOR]
      },
      {
        title: 'Invoices',
        icon: ReceiptIcon,
        href: '/finance',
        allowedRoles: [USER_ROLES.ADMIN]
      },
      {
        title: 'Task manager',
        href: '/task-manager',
        icon: VerticalSplitIcon,
        allowedRoles: [USER_ROLES.ADMIN]
      },
      {
        title: 'Users',
        href: '/user-management',
        icon: UserIcons,
        allowedRoles: [USER_ROLES.ADMIN]
      },
    ]
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: 240,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: 240,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: '#EDF1F5',
    border: "none"
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: '#EDF1F5',
    overflowX: 'hidden',
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7),
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    background: '#EDF1F5',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  appVersion: {
    display: "flex",
    flex: 1,
    padding: theme.spacing(2),
    justifyContent: "flex-start",
    textTransform: "lowercase"
  }
}));


function renderNavItems({
  items,
  pathname,
  depth = 0
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}) {
  const key = item.title + depth;
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const NavBar = ({ handleDrawerClose, isOpen }) => {
  const classes = useStyles();
  const { user } = useAuth();

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isOpen,
        [classes.drawerClose]: !isOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <p className={classes.appVersion}>v{APP_VERSION}</p>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {sections.map((section) => (
          <List
            key={section.subheader}
            subheader={(
              <ListSubheader
                disableGutters
                disableSticky
              >
                {section.subheader}
              </ListSubheader>
            )}
          >
            {renderNavItems({
              items: section.items.filter(item => item.allowedRoles.includes(user.role)),
              pathname: location.pathname
            })}
          </List>
        ))}
      </PerfectScrollbar>
    </Drawer>
  );
}

NavBar.propTypes = {
  handleDrawerOpen: PropTypes.func,
  handleDrawerClose: PropTypes.func
};

NavBar.defaultProps = {
  handleDrawerOpen: () => { },
  handleDrawerClose: () => { }
};

export default NavBar;