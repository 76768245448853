import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography, Box,
  useTheme,
  makeStyles,
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import Account from './Account';
import Notifications from './Notifications';
import Settings from './Settings';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    backgroundColor: theme.palette.common.white,
    color: '#2E2E38',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: 240,
    width: `calc(100% - ${240}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 15,
  },
  hide: {
    display: 'none',
  },
  toolbar: {
    minHeight: 120
  }
}));

const TopBar = ({
  handleDrawerOpen,
  isOpen,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const topBarStyle = {
    background: "#fff",
  }

  return (
    <AppBar style={topBarStyle}
      position="fixed"
      className={clsx([classes.root, classes.appBar], {
        [classes.appBarShift]: isOpen,
      })}
      {...rest}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: isOpen,
          })}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h3" noWrap style={{ fontWeight: theme.typography.fontWeightBold }}>
          DEAL <span style={{ color: theme.palette.primary.main }}>FLOW</span>
        </Typography>
        {/* <Logo /> */}
        <Box
          ml={2}
          flexGrow={1}
        />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar >
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  handleDrawerOpen: PropTypes.func
};

TopBar.defaultProps = {
  handleDrawerOpen: () => { }
};

export default TopBar;
