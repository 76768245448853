import { browserHistory } from 'react-router';
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_DNS,
  headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      //dispatch somehow
      return window.location.href = "/";
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong')
  }
);

export default axiosInstance;
